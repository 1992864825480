  import React from 'react'
  import Layout from '../../../../components/App/Layout'
  import Navbar from '../../../../components/App/NavbarRV'
  import Footer from '../../../../components/App/FooterRV'
  import KDPImagePage from '../../../../assets/images/pages/1-1-3-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-construction/'>Expertise construction</a></li><li><a href='/expertise-construction/expert-construction/'>L'expert construction</a></li><li>Déroulement de la mission</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Déroulement de la mission d’Expertise</h1>

<p>Que ce soit pour une expertise de partie, une expertise d’assuré ou une assistance pour une contre expertise ou en expertise judiciaire, nos Experts respectent un protocole strict permettant de répondre au mieux à vos attentes. </p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Déroulement de la mission" />
</div>


<ol><li>Lors de votre sollicitation, nous définissons les contours, le contexte, les objectifs et les attentes de votre demande d’expertise. </li>
<li>Nous établissons un devis adapté aux besoin de la mission,</li>
<li>Nous effectuons une visite technique in situ. L’Expert, lors de sa visite, répertorie tous les éléments nécessaires à son analyse et à l’établissement de son rapport. Selon besoin, il effectue des mesures sur place (taux d'humidité, détection d’armatures béton,inspection laser), afin de répertorier sur plan et dans le rapport les désordres.</li>
<li>Analyses en bureau : une fois l’analyse technique in situ effectué, l’Expert analyse ses données, effectue des vérifications comparatives aux DTU en vigueur, vérifie la conformité des plans, contrats, compris dans les cahier des charges, études de sol afin d’établir une analyse technique expertale fine et argumentée.</li>
<li>Remise du rapport : L’Expert vous remet un rapport qui comporte :</li>
</ol>
<ul><li>un avis technique général, </li>
<li>un recensement photographiques des désordres, </li>
<li>la détermination des non conformités et défauts d'exécution ainsi que les causes de ces désordres. </li>
<li>les solutions techniques réparatoires adaptées,</li>
<li>le coût estimatif des travaux,</li></ul>

<p>Le cas échéant, le rapport d’expertise peut engager la responsabilité d’un exécutant, d’une entreprise ou d’une assurance</p>


<p>Pour en savoir plus, vous pouvez <u>contacter</u> un Expert RV Expertises</p>



                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-construction/expert-construction/mission/' className='active'>Déroulement de la mission</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details